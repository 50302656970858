import { baseApiAxios, showError } from '../../../js/portal/api';
import { getSearchParamsUrl, setSearchParamsUrl } from '../../../js/portal/search_params';
import { showAccountDetailsModal, removeDisabledOptions, showErrorFromApiOperation } from '../../../js/portal/main';
import { addSpinners } from '../../../js/portal/sidebar';

/**
 * Creates a new selectpicker dropdown including a label, an optional account detail lookup button and a refresh
 * button. The dropdown list will be populated automatically with all AWS accounts from our account information DB.
 *
 * @param {string} label - name label on the left side of the dropdown menu
 * @param {string} id - the id of the selectpicker
 * @param {function} onchange - a function that will be triggered once the selectpicker value changes
 * @param {function} onrefresh - function that is triggered when the refresh button is clicked
 * @param {boolean} multiple - defines if you can select one or multiple items in the user dropdown
 * @param {boolean} short - defines if a reduced dataset is loaded from the API
 * @param {boolean} listAll - display all accounts that the user has permission to see/edit
 * @param {boolean} lookup - defines if the account lookup button should be added to the selectpicker
 * @param {string} accountStageFilter - stage name for disabling account options in the dropdown
 * @param {boolean} inline - inline layout without label and additional buttons for use in e.g. breadcrumbs
 * @param {boolean} disableUrlParams - disable setting and reading URL parameters
 * @param {boolean} disable - disable field
 *
 * @returns div including a label, the dropdown, an optional lookup button and a refresh button
 */
export default function AccountDropdown({
  label,
  id,
  onchange,
  onrefresh,
  multiple,
  short,
  listAll,
  listDeleted,
  lookup,
  accountStageFilter,
  inline,
  disableUrlParams,
  disable,
}) {
  const labelText = label ? label : 'AWS Account Id';
  const selectId = id ? id : 'aws-account-id';
  const headers = { 'Cache-Control': 'max-age=0, must-revalidate' };
  const params = short ? { short: true } : { all: !!listAll, deleted: !!listDeleted };
  const inlineLayout = inline ? true : false;
  const urlParameters = !disableUrlParams ? true : false;
  const disableField = !disable ? true : false;

  // selectpicker options: https://developer.snapappointments.com/bootstrap-select/options/
  const select = (
    <select
      required
      disabled={disableField}
      class="form-control form-select selectpicker"
      data-live-search="true"
      data-show-subtext="true"
      id={selectId}
      name={selectId}
      data-size="10"
      data-max-options="10"
      data-none-selected-text="- Select an AWS Account ID -"
      data-selected-text-format="count > 2"
      data-count-selected-text="{0} accounts selected"
      data-dropdown-align-right="auto"
      multiple={multiple ? true : false}>
      <option class="defaultDisabled" value="" disabled selected={multiple ? false : true}>
        - Select an AWS Account ID -
      </option>
    </select>
  );

  // Account detail lookup button to load and show the account details of the selectec account
  const button = (
    <button disabled type="button" class="btn btn-light-grey input-group-btn" id="show-account-details">
      <i class="fas fa-search" />
      <span class="ms-2">Show account details</span>
    </button>
  );

  // Refresh button to reload the account details
  const refreshIcon = <i class="fas fa-sync" />;
  const refreshButton = (
    <button
      type="button"
      class="btn btn-light-grey input-group-btn portal-selectpicker-end"
      id="refresh-accounts"
      title="Reload Account List">
      {refreshIcon}
    </button>
  );

  // Represents the whole row including the label, the selectpicker, the optional lookup button and the refresh button
  const accountDropdown = (
    <div class={'form-group' + (inlineLayout ? '' : ' row') + ' portal-dropdown'}>
      {labelText != 'none' && (
        <label for={selectId} class="col-form-label">
          {labelText}
        </label>
      )}
      <div class="col input-group">
        {select}
        {refreshButton}
        {lookup ? button : undefined}
      </div>
    </div>
  );

  // Refresh the selectpicker and add an on change event
  $(select).selectpicker('refresh');
  $(select).on('changed.bs.select', () => {
    $(button).removeAttr('disabled');
    const selectedOption = $(select).find(':selected');
    const selectedAccountId = selectedOption.val();
    const selectedAccountType = selectedOption.attr('account_type');
    const selectedAccountStage = selectedOption.attr('account_stage');

    if (onchange && selectedAccountId && !selectedOption.hasClass('defaultDisabled')) {
      if (multiple) {
        onchange('account_id', 'account', selectedOption, $(select).val());
      } else {
        onchange('account_id', 'account', selectedOption, selectedAccountId, selectedAccountType, selectedAccountStage);
      }
    }

    if ((lookup || inlineLayout) && urlParameters) {
      const previousUrlParam = getSearchParamsUrl('account_id');
      if (selectedAccountId && selectedAccountId !== previousUrlParam) {
        setSearchParamsUrl({ account_id: selectedAccountId });
      }
    }
  });

  $(select).on('reload-dropdown-options', event => {
    const additionalParams = {
      all: event?.detail?.allResources,
    };

    loadAccounts(
      select,
      refreshIcon,
      lookup,
      inlineLayout,
      urlParameters,
      headers,
      additionalParams,
      accountStageFilter,
    );
  });

  // Add an on click event to lookup the account details from the REST API
  $(button).on('click', () => {
    const selectedOption = $(select).find(':selected');
    const selectedAccountId = selectedOption.val();
    addSpinners();
    baseApiAxios
      .getAccount(selectedAccountId)
      .then(showAccountDetailsModal)
      .catch(showErrorFromApiOperation('Failed to fetch account details'));
  });

  $(refreshButton).on('click', event => {
    // Reload the account records from the REST API (with cache-control headers)
    if (onrefresh) {
      onrefresh(event, select);
    } else {
      loadAccounts(select, refreshIcon, lookup, inlineLayout, urlParameters, headers, params, accountStageFilter);
    }
  });

  // Load the account records from the REST API (without cache-control headers)
  loadAccounts(select, refreshIcon, lookup, inlineLayout, urlParameters, {}, params, accountStageFilter);
  return accountDropdown;
}

/**
 * Populates the selectpicker dropdown with the values from the REST API endpoint. Deletes all active dropdown items
 * before adding the new items.
 *
 * @param {JSX.IntrinsicElements.select} select
 * @param {JSX.Element} refreshIcon
 * @param {boolean} lookup
 * @param {boolean} urlParameters
 * @param {object} headers
 * @param {object} params
 * @param {string} accountStageFilter
 */
async function loadAccounts(
  select,
  refreshIcon,
  lookup,
  inlineLayout,
  urlParameters,
  headers,
  params,
  accountStageFilter,
) {
  $(refreshIcon).addClass('fa-spin');
  $(select).attr('disabled', true).selectpicker('refresh');

  try {
    removeDisabledOptions(select);

    const url_params = Object.assign(
      {
        attribute_names: ['account_id', 'account_friendly_name', 'account_type', 'account_stage'].join(','),
      },
      params,
    );

    const accounts_response = await baseApiAxios.getAccounts(headers, url_params);
    // Retrieve a account_id from the URL search parameters
    const selected_account = (lookup || inlineLayout) && urlParameters ? getSearchParamsUrl('account_id') : null;

    // Both values are used for the dashboard to update the "AWS Accounts" box
    let number_accounts = { default: 0, advanced: 0, other: 0 };
    let is_cdh_user_roles = false;

    // Loop over all account items to add all account items as options to the selectpicker, to count the number of
    // default, advanced and other accounts and to check if there are any CDH user roles for any of those accounts
    const options = accounts_response.map(item => {
      // Count the number of default, advanced and other (everything beside of default and advanced) accounts
      if (Object.prototype.hasOwnProperty.call(number_accounts, item.account_type)) {
        number_accounts[item.account_type] += 1;
      } else {
        number_accounts.other += 1;
      }

      // Check if the current user owns any CDH role. This information is required to show the CDH portal login link
      if (item.cdh_user_roles?.length > 0) is_cdh_user_roles = true;

      // Prepare the actual dropdown item
      const opt = document.createElement('option');
      opt.value = item.account_id;
      opt.setAttribute('account_type', item.account_type);
      opt.setAttribute('account_stage', item.account_stage);
      opt.dataset.content =
        `<span class="bs-dropdown-badge account">${item.account_id}</span>` +
        `<span class="bs-dropdown-item-text">${item.account_friendly_name}</span>` +
        `<span class="bs-dropdown-badge">${item.account_type}</span>` +
        `<span class="bs-dropdown-badge new">${item.account_stage}</span>`;
      opt.selected = selected_account && selected_account === item.account_id ? true : false;
      opt.disabled = accountStageFilter && accountStageFilter === item.account_stage ? true : false;
      return opt;
    });

    // Sort the selectpicker options in asc order by the option text
    options.sort(function (a, b) {
      return a.value.localeCompare(b.value);
    });

    // Add all options to the selectpicker
    options.forEach(option => {
      select.appendChild(option);
    });

    // Trigger the `dropdown-options-loaded` event for the portal dashboard to update the account numbers and links
    const event = new CustomEvent('dropdown-options-loaded', {
      detail: {
        number_active: options.length,
        number_default: number_accounts.default,
        number_advanced: number_accounts.advanced,
        number_other: number_accounts.other,
        list_all: params.all,
        is_cdh_user_roles,
      },
    });
    select.dispatchEvent(event);
    $(select).trigger('changed.bs.select');
  } catch (err) {
    showError(err);
  }

  // Refresh the selectpicker to show the new options
  $(refreshIcon).removeClass('fa-spin');
  $(select).attr('disabled', false);
  $(select).selectpicker('refresh');
}
