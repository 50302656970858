import { next4wheelsTab, previous4wheelsTab, show4wheelsTab } from './js/portal/fourwheels';
import { loadRegionsAndUpdateDropdown, showAndProcessRegionRestrictWarning } from './js/portal/main';
import { addButtonSpinner, addSpinner, removeSpinners } from './js/portal/sidebar';
import { checkValidityHostedZone } from './js/portal/zones';
import AccountDropdown from './jsx/components/forms/AccountDropdown';

export default function FourWheelsCreatePage() {
  const page = (
    <div class="row">
      <div class="col-lg-12">
        <form name="create-cluster-form" id="create-cluster-form" method="POST">
          <div class="card form-card">
            {/* CARD HEADER */}
            <div class="card-header">Create New 4wheels Kubernetes Cluster</div>

            {/* CARD BODY */}
            <div class="card-body">
              <div id="form-error" class="alert alert-danger" style="display: none">
                <ul />
              </div>

              <div id="form-success" class="alert alert-success" style="display: none">
                <ul />
              </div>

              {/* START THE TABS */}

              <div id="tabs" class="tab-content">
                <div id="tabs" class="tab-content">
                  {/* TAB: ACCOUNT, REGION, NAME */}
                  <div id="basic-settings" class="tab">
                    <div id="infobox" class="card infobox">
                      <div class="card-body">
                        <p>
                          <strong>
                            To create your own BMW 4wheels Kubernetes cluster, simply submit the form below.
                          </strong>
                        </p>
                        <p>
                          4wheels Guided is a Kubernetes cluster based on AWS EKS and it has been automated for you by
                          us! What does that mean? Well, after you order a cluster we will create a network integrated
                          Kubernetes cluster in your AWS account (
                          <strong>account with stage equal to basic are not allowed</strong>). This cluster is
                          afterwards fully integrated and can be used with VPC integration. You need more details? Check
                          out the{' '}
                          <a
                            href="https://developer.bmwgroup.net/docs/4wheels-guided/"
                            target="_blank"
                            rel="noopener noreferrer">
                            Developer Portal
                          </a>
                          !
                        </p>
                        <p>
                          <strong class="portal-danger">Important Note:</strong> 4wheels Guided is NOT BANK COMPLIANT
                        </p>
                      </div>
                    </div>

                    {/* ACCOUNT ID START */}
                    <div class="form-group row">
                      <label for="aws-account-id" class="col-form-label">
                        AWS Account Id
                      </label>
                      <div class="col col-md-12 col-lg-9 col-xl-10">
                        <AccountDropdown
                          id="aws-account-id"
                          label="none"
                          short
                          lookup
                          accountStageFilter="basic"
                          inline
                        />
                      </div>
                    </div>

                    {/* REGION START */}
                    <div class="form-group row">
                      <label for="region" class="col-form-label">
                        AWS Region
                      </label>
                      <div class="col">
                        <select
                          required
                          class="form-control form-select selectpicker"
                          data-live-search="true"
                          data-show-subtext="true"
                          data-size="10"
                          id="region"
                          name="region"
                          onchange={() => {
                            showAndProcessRegionRestrictWarning(
                              'region',
                              'acknowledge-denied-region',
                              'selected-denied-region',
                            );
                          }}
                        />
                      </div>
                    </div>
                    {/* REGION END */}

                    {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX START */}
                    <input type="checkbox" id="acknowledge-denied-region" name="acknowledge-denied-region" hidden />
                    <input type="checkbox" id="selected-denied-region" name="selected-denied-region" hidden />
                    {/* HIDDEN ACKNOWLEDGE DENIED REGIONS CHECKBOX END */}

                    {/* PROJECT NAME START */}
                    <div class="form-group row">
                      <label for="project-name" class="col-form-label" id="project-name-label">
                        Project Name
                      </label>
                      <div class="col">
                        <input
                          required
                          type="text"
                          class="form-control"
                          id="project-name"
                          name="project-name"
                          placeholder="your-4wheels-project-name"
                        />
                      </div>
                    </div>
                    {/* PROJECT NAME END */}

                    {/* CLUSTER SIZE START */}
                    <div class="form-group row">
                      <label for="cluster-size" class="col-form-label">
                        Cluster Size
                      </label>
                      <div class="col">
                        <select
                          required
                          class="form-control form-select selectpicker"
                          data-live-search="true"
                          data-show-subtext="true"
                          data-size="10"
                          id="cluster-size"
                          name="cluster-size">
                          <option data-subtext="general-purpose node large / 3 nodes" value="S" selected="selected">
                            Small
                          </option>
                          <option data-subtext="general-purpose node xlarge / 3 nodes" value="M">
                            Medium
                          </option>
                          <option data-subtext="general-purpose node 2xlarge / 3 nodes" value="L">
                            Large
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* CLUSTER SIZE END */}
                    {/* LOGGING STACK START */}
                    <div class="form-group row">
                      <label for="logging-stack" class="col-form-label">
                        Logging Stack
                      </label>
                      <div class="col">
                        <select
                          required
                          class="form-control form-select selectpicker"
                          data-live-search="true"
                          data-show-subtext="true"
                          data-size="10"
                          id="logging-stack"
                          name="logging-stack">
                          <option data-subtext="Traditional EFK stack" value="EFK">
                            ElasticSearch, Fluentd, Kibana
                          </option>
                          <option
                            data-subtext="New functionality utilizing Grafana to read Loki logs"
                            value="LOKI"
                            selected="selected">
                            Loki
                          </option>
                          <option data-subtext="No logging will be installed" value="NONE">
                            None
                          </option>
                        </select>
                      </div>
                    </div>
                    {/* LOGGING STACK END */}
                  </div>
                  {/* SECOND TAB: ACCOUNT, REGION, NAME */}

                  {/* <h4 class="h4line"><span>Network Configuration</span></h4> */}

                  {/* THIRD TAB: VPC */}
                  <div id="vpc-settings" class="tab">
                    {/* WELL INFOBOX START */}
                    <div id="infobox" class="card infobox">
                      <div class="card-body">
                        <p>
                          The 4wheels cluster deployment currently only supports existing Private VPCs. The minimum
                          requirements for your Private VPC(s) are:
                        </p>

                        <ul>
                          <li>
                            There are at least 3 subnets in your Private VPC which are connected to the BMW corporate
                            network (=intranet subnets). Every subnet has at least 15 IP addresses available. Each
                            subnet has a unique availability zone.
                          </li>
                          <li>
                            4wheels will create and add one Secondary CIDR 10.19.x.x (with 3 private subnets) during the
                            cluster creation if there is not one already attached to the VPC.
                          </li>
                          <li>
                            If you decided to create the Secondary CIDR yourself ensure that there are there at least 3
                            private subnets. Every subnet has at least 250 IP addresses available and each has a unique
                            availability zone.
                          </li>
                        </ul>

                        <p>
                          If you can't find any supported VPCs in the dropdown, please order a new Private VPC with{' '}
                          <strong>network size /25</strong> and <strong>3 subnets</strong>. You can order this subnet by
                          yourself in the <a href="#createnetwork">self service portal</a>.
                        </p>
                      </div>
                    </div>
                    {/* WELL INFOBOX END */}

                    <div class="form-group row">
                      <label for="network-id" class="col-form-label">
                        VPC
                      </label>
                      <div class="col">
                        <select
                          required
                          class="form-control form-select selectpicker"
                          data-live-search="true"
                          data-show-subtext="true"
                          data-size="10"
                          id="network-id"
                          name="network-id"
                          data-dropdown-align-right="auto">
                          <option value="" disabled selected>
                            - Select a VPC -
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {/* THIRD TAB: VPC */}

                  {/* <h4 class="h4line"><span>DNS Configuration</span></h4> */}

                  {/* FOURTH TAB: DNS */}
                  <div id="dns-settings" class="tab">
                    {/* WELL INFOBOX START */}
                    <div id="infobox" class="card infobox">
                      <div class="card-body">
                        <p>
                          <strong>
                            To create your own BMW 4wheels Kubernetes cluster, simply submit the form below
                          </strong>
                          <br />
                          4wheels is a Kubernetes cluster based on AWS EKS and it has been automated for you by us! What
                          does that mean? Well, after you order a cluster we will create a network integrated Kubernetes
                          cluster in your AWS account (
                          <strong>account with stage equal to basic are not allowed</strong>). This cluster is
                          afterwards fully integrated and can be used with VPC integration. You need more details? Check
                          out the documentation!
                        </p>
                      </div>
                    </div>
                    {/* WELL INFOBOX END */}

                    {/* HOSTED ZONE SELECT START */}
                    <div class="form-group row">
                      <label for="hosted-zone-id" class="col-form-label">
                        Route53 Private Hosted Zone
                      </label>
                      <div class="col">
                        <div id="hosted-zone-id-container">
                          <select
                            required
                            class="form-control form-select selectpicker"
                            data-live-search="true"
                            data-show-subtext="true"
                            data-size="10"
                            id="hosted-zone-id"
                            name="hosted-zone-id"
                            data-dropdown-align-right="auto"
                            regex-string="^[a-z0-9][a-z0-9.-]{1,45}$"
                            onchange={checkValidityHostedZone}>
                            <option value="" disabled selected>
                              - Select a FQDN -
                            </option>
                          </select>
                        </div>
                        <div class="valid-feedback" id="hosted-zone-id-valid-feedback">
                          Domain is valid
                        </div>
                        <div class="invalid-feedback" id="hosted-zone-id-invalid-feedback">
                          EKS (4wheels Guided is based on EKS) has a limitation of 46 characters for the length of the
                          FQDN (RFC-1123).
                        </div>
                      </div>
                    </div>
                    {/* HOSTED ZONE SELECT END */}
                  </div>
                </div>
                {/* FOURTH TAB: DNS */}
              </div>

              {/* END THE TABS */}
            </div>

            {/* CARD FOOTER */}
            <div class="card-footer">
              <input type="hidden" name="action" id="action" value="createJob" />
              <input type="hidden" name="jobType" id="jobType" value="create-4wheels-cluster" />
              <input type="hidden" name="jobTypeDescription" id="jobTypeDescription" value="Create 4wheels Cluster" />
              <div class="row px-2">
                <div class="col">
                  <input
                    type="button"
                    class="btn btn-secondary d-none"
                    id="previousTabBtn"
                    name="previousTabBtn"
                    onclick={() => previous4wheelsTab()}
                    value="Back"
                  />
                </div>
                <div class="col" align="right">
                  <button id="submitButton" type="submit" class="btn btn-success" value="Continue">
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );

  function onPageReady() {
    $.validator.addMethod(
      'projectname',
      function (value, element, regexp) {
        const re = new RegExp(regexp);
        return this.optional(element) || re.test(value);
      },
      'Please enter a valid Project Name. Start with a letter and enter between 4 and 19 alphanumerical lowercase characters: ^[a-z][a-z0-9-]{1,17}(?!<=\\W)[^-\\WA-Z]$',
    );

    loadRegionsAndUpdateDropdown('private', ['region']);

    show4wheelsTab(0);

    $('.selectpicker').selectpicker('refresh');
    $('.selectpicker').not('.no-deselect').selectpicker('deselectAll');

    $('#create-cluster-form')
      .on('submit', e => {
        e.preventDefault();
        $('#form-error').hide();
        $('#form-success').hide();
        addSpinner();
        addButtonSpinner();
      })
      .validate({
        errorContainer: '#form-error',
        errorLabelContainer: '#form-error ul',
        wrapper: 'li',
        rules: {
          'project-name': {
            minlength: 4,
            maxlength: 19,
            // projectname: '^[a-z]{1}[a-z0-9\-]{3,18}$',
            projectname: '^[a-z][a-z0-9-]{1,17}(?!<=W)[^-WA-Z\\s]$',
          },
        },
        messages: {
          'aws-account-id': {
            required: 'Please select your target AWS Account ID.',
          },
          region: {
            required: 'Please select the target AWS region.',
          },
          'project-name': {
            required: 'Please enter an unique Project Name.',
            minlength: 'Project Name must have between 4 and 19 characters.',
            maxlength: 'Project Name must have between 4 and 19 characters.',
          },
          'cluster-size': {
            required: 'Please select the target Cluster Size.',
          },
          'logging-stack': {
            required: 'Please select the logging stack.',
          },
          'network-id': {
            required:
              "Please select a VPC ID. If there's no supported VPC available, create a new Private VPC with min " +
              '/25 network size and 3 Subnets.',
          },
          'hosted-zone-id': {
            required:
              "Please select a Route 53 Private Hosted Zone. If there's no Hosted Zone available, create a new " +
              'Private Hosted Zone in your target AWS region.',
          },
        },
        submitHandler: function () {
          next4wheelsTab();
        },
        invalidHandler: function (_event, validator) {
          const errors = validator.numberOfInvalids();
          removeSpinners();
          console.error(errors);
        },
      });
  }

  return [page, onPageReady];
}
